
<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"
        @addRole="addRole"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @editRole="editRole"
        @deleteRole="deleteRole"

    />
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addRoleData"
        @addRoleSub="addRoleSub"
    />
  </div>
</template>
<script>
// import {timer} from '@/assets/js/comment'
import {getAllAuthority, getAllRole, addRole, editRole, getRoleDetile, deleteRole} from '@/api/basic_management/permission_settings'

export default {
  components: {},
  props: [],
  data() {
    return {
      allAuthority: [],
      //title 数据源
      titleProp: {
        name: '权限设置',//标题名称
        butOperate: [{
          name: '添加角色',
          type: 'primary',
          method: 'addRole',
          id: 2000,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [/*{
          title: 'ID',
          field: 'id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, */{
          title: '角色名称',
          field: 'group_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '编辑',
            type: 'primary',
            method: 'editRole',
            status: '20000',
            id: 20000,
          }, {
            name: '删除',
            type: 'danger',
            method: 'deleteRole',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //addDialog数据源
      addRoleData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addRoleSub',//点击确定方法名
        title: '添加角色',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '角色名称',
          field: 'group_name',
          type: 2,
          show: true,
        },{
          label: '权限信息',
          field: 'jurisdiction_id_json',
          type: 13,
          show: true,
        }],
        //dialog 显示数据
        addDialogShowData: {
          group_name: '',
          jurisdiction_id_json: {
            ref:'jurisdiction',
            props:{
              label: 'jurisdiction_name',
              children: 'children',
            },
            checkbox: 'true',
            data: [],
            checkId: [],
          },
        },
        // 下拉框的 options和单选框，多选框
      },
      //表格数据
      tableData: [],
      //addDialog提交数据
      editId:'',

    };
  },
  created() {
    this.initLoad();
    this.getAllAuthority()
  },
  mounted() {
  },
  methods: {
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        // keywords: this.tabsProp.keywords,
        // this.tabsProp.active //tabs 当前的值
        // name: this.tabsProp.heighKewWordField.name
      }
      getAllRole(params).then((result) => {
        console.log(result)
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        this.tableData = res.data
      })
    },
    //title点击 添加角色 按钮
    addRole() {
      console.log('添加角色')
      this.editId = ''
      this.addRoleData.title = '添加角色'
      this.addRoleData.addDialogShowData.jurisdiction_id_json.checkId = ''
      this.addRoleData.addDialogShowData.group_name = ''
      this.addRoleData.addDialogShowData.jurisdiction_id_json.data = [...this.allAuthority]
      this.addRoleData.isDialog = true
    },
    //获取所有权限
    getAllAuthority(){
      getAllAuthority().then((result) => {
        let res = result.data;
        this.allAuthority = res
      })
    },
    //table 编辑 按钮点击
    editRole(row, index) {
      console.log(row)
      console.log(index)
      console.log('编辑 点击')
      this.addRoleData.title = '编辑角色'
      this.addRoleData.addDialogShowData.jurisdiction_id_json.data = [...this.allAuthority]
      this.addRoleData.addDialogShowData.jurisdiction_id_json.checkId = ''
      this.addRoleData.addDialogShowData.group_name = ''
      this.editId = row.id
      this.addRoleData.isDialog = true
      //获取当前角色权限
      getRoleDetile({id : row.id}).then((result) => {
        this.addRoleData.addDialogShowData.jurisdiction_id_json.checkId = JSON.parse(result.data.jurisdiction_id_json)
        this.addRoleData.addDialogShowData.group_name = result.data.group_name
      })
    },
    //table 作废 按钮点击
    deleteRole(row, index) {
      console.log(row)
      console.log(index)
      console.log('作废 点击')
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRole({id: row.id}).then((result) => {
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.tableData.splice(index, 1)
        })
            .catch((error) => {
              console.log(22);
              this.$message({
                message: error.msg,
                type: 'error'
              });
            })
      })
    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      console.log('当前页:' + val);
      this.initLoad();
    },
    // addDialog 点击确定
    addRoleSub() {
      let subData = {}
      subData.group_name = this.addRoleData.addDialogShowData.group_name
      subData.jurisdiction_id_json = JSON.stringify(this.addRoleData.addDialogShowData.jurisdiction_id_json.checkId)

      if (this.editId){
        console.log('编辑')
        subData.id = this.editId
      editRole(subData).then((result) => {
        this.addRoleData.isDialog = false;
        this.$message({
          message: result.msg,
          type: 'success'
        });
        this.initLoad()
      })
      .catch((error) => {
            this.$message({
              message: error.msg,
              type: 'error'
            });
          })
      } else {
        console.log('添加')
        addRole(subData).then((result) => {
          this.addRoleData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
        .catch((error) => {
              console.log(22);
              this.$message({
                message: error.msg,
                type: 'error'
              });
            })
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
