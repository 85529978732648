import http from '../http';

export function getAllAuthority (params) {
    return http({
        url: "/api/ltd/jurisdictionAll",
        method: "get",
        params:params
    })
}
export function getAllRole (params) {
    return http({
        url: "/api/ltd/userGroupPage",
        method: "get",
        params:params
    })
}
export function addRole (data) {
    return http({
        url: "/api/ltd/userGroup",
        method: "put",
        data:data
    })
}
export function editRole (data) {
    return http({
        url: "/api/ltd/userGroup",
        method: "post",
        data:data
    })
}
export function getRoleDetile (params) {
    return http({
        url: "/api/ltd/userGroup",
        method: "get",
        params:params
    })
}
export function deleteRole (params) {
    return http({
        url: "/api/ltd/userGroup",
        method: "delete",
        params:params
    })
}
